.header__container {
  display: flex;
  align-items: center;
  height: 104px;
  background-color: var(--page-background);
  width: 100%;
  position: fixed;
  z-index: 100;
}

.header__content {
  display: flex;
  justify-content: space-between;
  width: 1024px;
  margin: 0 auto;
}

.header__navigation-container {
  display: flex;
  gap: 10px;
}

.header__navigation-tab {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 48px;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--main-text);
  background-color: var(--page-background-light);
  border-radius: 4px;

  &:hover {
    background-color: var(--item-hover);
  }

  &:active {
    background-color: var(--item-active);
  }
}

.user-logout__container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--main-text);
  cursor: default;
}

.user-logout__button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--page-background-light);

  &:hover {
    background-color: var(--item-hover);
  }

  &:active {
    background-color: var(--item-active);
  }
}

.layout__menu-wrapper {
  position: relative;
}

