.facility-block-image-panel__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  padding: 40px 20px 20px 20px;
  background-color: var(--page-background-light);
  flex: 1;
}

.facility-block-image-panel__image-row {
  display: flex;
  justify-content: space-around;
}
