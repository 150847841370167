*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
}

input {
  border: none;
  outline: none;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

div[role='button']:focus-visible {
  outline: none;
}

div:focus-visible,
canvas:focus-visible {
  outline: none;
}

button {
  -webkit-tap-highlight-color: transparent;
}

.dark-theme {
  font-family: 'Source Sans Pro', sans-serif;
  --page-background: #272727;
  --page-background-light: #3C3C3C;
  --selected-item: #363636;
  --panel-background: #2F2F2F;
  --button-normal: #5F9BFA;
  --button-normal-hover: #6AA6FF;
  --button-normal-active: #5184D4;
  --button-normal-disabled: #40526F;
  --button-secondary: transparent;
  --button-secondary-hover: #CCCCCC;
  --button-secondary-active: #F0F0F0;
  --button-secondary-disabled: transparent;
  --main-text: #F0F0F0;
  --main-text-dark: #323232;
  --label-text: #999999;
  --title-text: #CCCDCD;
  --input-background: #242425;
  --input-background-hover: #29292A;
  --input-background-active: #262627;
  --input-border: #4A4A4A;
  --input-hover-border: #494949;
  --input-placeholder: #676767;
  --item-border: #595959;
  --item-hover: #424242;
  --item-active: #3B3939;
  --error: #F15050;
  --popup-background: #1D1D1F;
  --popup-item-hover: #313133;
  --popup-item-selected: #2A2A2C;
  --background-hover: #2E2E2F;
  --background-active: #2A2A2B;
  --background-light-hover: #4E4E4E;
  --background-light-active: #383636;
  --separator-dark: #202020;
  --separator-light: #616161;
}
