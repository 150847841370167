.dialog-window__background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.48);
}

.dialog-window__container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 460px;
  background: var(--panel-background);
  border: 1px solid #202020;
  border-radius: 4px;
  transform: translate(-50%, -50%);

  &.choice-window__container {
    width: 600px;
  }
}

.dialog-window__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;

  & .dialog-window__buttons-wrapper {
    align-self: flex-end;
  }
}

.dialog-window__title-wrapper {
  position: relative;
  padding: 12px 16px;
  background-color: var(--input-background);
}

.dialog-window__title {
  color: var(--main-text);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &.error {
    color: #ff3131;
  }
}

.dialog-window__message {
  max-width: 90%;
  color: var(--main-text);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  text-overflow: ellipsis;
}

.dialog-window__close-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  border-radius: 4px;

  &:hover {
    background-color: var(--background-hover);
  }

  &:active {
    background-color: var(--background-active);
  }
}

.dialog-window__buttons-wrapper {
  display: flex;
  gap: 12px;
  height: 40px;
  margin-top: 24px;

  & .dialog-window__button {
    min-width: 128px;
  }
}

.dialog-window__with-icon-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dialog-window__icon-wrapper {
  position: relative;
  width: 36px;
  height: 36px;

  & svg{
    position: absolute;
    width: 36px;
    height: 36px;
    top: 4px;
  }
}





