.facility-block__container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.selected {
    padding-left: 0;
    // background-color: var(--page-background-light);

    & .block__control-arrows,.block__lock{
      display: flex;
    }
  }
}

.facility-block__name-container {
  display: flex;
  gap: 12px;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
}

.block__lock {
  position: relative;
  display: none;
  width: 20px;
  height: 39px;
  background-color: #4E4E4E;

  & svg {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translateY(-50%) translateX(-40%);
  }
}

.block__control-arrows {
  display: none;
  flex-direction: column;
  background-color: #4E4E4E;
}

.block__control-arrow {
  display: flex;
  position: relative;
  width: 20px;
  height: 19.5px;
  background-color: #4E4E4E;

  &.up-arrow {
    transform: rotate(180deg);
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    & path {
      fill: var(--label-text);
    }
  }

  &:disabled {
    cursor: default;

    & svg path{
      fill: #717171;
    }
  }

  &:not(:disabled):hover {
    background-color: #5C5C5C;
  }

  &:not(:disabled):active {
    background-color: #474747;
  }
}
