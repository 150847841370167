.layout__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: var(--input-background);
  position: fixed;
  width: 100%;
  z-index: 100;
}

.layout__icn-back {
  display: flex;
  position: relative;
  width: 36px;
  height: 36px;
  align-items: center;
  margin-right: 16px;
  border-radius: 4px;

  &.selected {
    background-color: var(--popup-background);
  }

  &:not(.selected):hover {
    background-color: var(--background-hover);
  }

  &:active {
    background-color: var(--background-active);
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.layout__title {
  width: 80%;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--main-text);
  cursor: default;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layout__left-header-content {
  display: flex;
  align-items: center;
  width: 20%;
}

.layout__version-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 20%;
}

.layout__version-btn {
  display: flex;
  align-items: center;
  text-align: right;
  gap: 4px;
  justify-content: space-between;
  width: 50%;
  padding: 7px 8px;
  border-radius: 3px;
  border: 1px solid var(--input-border);

  &.invisible {
    height: 32px;
    border: none;
  }
}

.layout__version-btn-text {
  max-width: 90%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-text);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layout__tabs {
  display: flex;
  align-items: center;
  height: 52px;
  max-width: 59.5%;
  overflow-x: hidden;

  &:hover {
    overflow-x: overlay;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      position: relative;
      background: var(--panel-background);
      height: 7px;
      cursor: auto;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px 6px rgba(153, 153, 153, 0.24);
      -webkit-border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      border-top: solid 2px transparent;
      border-bottom: solid 2px transparent;
      border-left: solid 2px transparent;
      border-right: solid 2px transparent;
      min-width: 30px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px var(--input-background);
    }

    &::-webkit-scrollbar-track:horizontal {
      //border-top: solid 1px transparent;
      //border-bottom: solid 1px transparent;
    }
  }
}

.layout__tab {
  display: block;
  align-items: center;
  justify-content: space-around;
  flex-basis: 100%;
  padding: 0 12px;
  height: 100%;
  border-left: 1px solid var(--separator-dark);
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(204, 205, 205, 0.64);
  cursor: pointer;
  text-transform: uppercase;
  //overflow: auto;
  white-space: nowrap;
  //text-overflow: ellipsis;

  &:not(.selected):hover {
    background-color: #292929;
  }

  &.selected {
    background-color: var(--panel-background);
    cursor: default;
  }

  &:last-child {
    border-right: 1px solid var(--separator-dark);
  }
}

.layout__tab-name {
  display: flex;
  align-items: center;
  gap: 10px;
}

.layout__popup-menu {
  width: 212px;
  left: 0;
}
