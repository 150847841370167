.input__container-wrapper {
  position: relative;
}

.input__container {
  position: relative;
  width: 100%;
  padding: 7px 12px;
  background-color: var(--input-background);
  border-radius: 3px;
  border: 1px solid var(--input-border);

  &.error:not(.focus) {
    border: 1px solid var(--error);

    &:hover {
      border: 1px solid var(--error);
    }
  }

  &:not(.focus,.disabled,.error):hover {
    &:not(.hidden-input) {
      border: 1px solid var(--input-hover-border);
    }

    background-color: var(--input-background-hover);

    & .input {
      background-color: var(--input-background-hover);
    }
  }

  &.disabled {
    background-color: transparent;
  }

  &.hidden-input {
    border: none;

    & .input {
      cursor: pointer;
    }
  }
}

.input {
  display: block;
  width: 100%;
  padding: 0;
  color: var(--main-text);
  background-color: var(--input-background);
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  &::placeholder {
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    -webkit-text-fill-color: var(--input-placeholder);
    color: var(--input-placeholder);
  }

  -webkit-text-fill-color: var(--main-text);

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    caret-color: white;
  }

  &:disabled {
    background-color: transparent;
    color: var(--label-text);
    -webkit-text-fill-color: var(--label-text);
  }
}

.input-label {
  display: block;
  margin-bottom: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--label-text);
  text-align: left;
}
