
.facility__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 24px;
  border: 1px solid var(--item-border);
  border-radius: 2px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:hover {
    background: var(--item-hover);
  }

  &:active:not(:focus-within) {
    background: var(--item-active);
  }
}

.facility__name-row {
  display: block;
  text-align: left;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--main-text);
  max-width: 550px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.facility__details-row {
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--label-text);
}

.facility__delete-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  &:not(:disabled):hover {
    background-color: var(--background-light-hover);
  }

  &:not(:disabled):active {
    background-color: var(--background-light-active);
  }

  &:disabled {
    cursor: default;
  }
}




