.equipment-table__wrapper {
  overflow: auto;
  max-width: calc(100vw - 20.886vw - 2*20px);
  max-height: calc(100vh - 56px - 16px - 40px - 20px - 18px/*scrollbar*/);

  &::-webkit-scrollbar {
    position: relative;
    background: transparent;
    width: 26px;
    height: 26px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px rgba(153, 153, 153, 0.24);
    -webkit-border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-top: solid 9.5px transparent;
    border-bottom: solid 9.5px transparent;
    border-left: solid 9.5px transparent;
    border-right: solid 9.5px transparent;
    min-width: 30px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    border-top: solid 9.5px transparent;
    border-bottom: solid 9.5px transparent;
    border-left: solid 9.5px transparent;
    border-right: solid 9.5px transparent;
    min-height: 30px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px var(--input-background);
  }

  &::-webkit-scrollbar-track:horizontal {
    border-top: solid 8px transparent;
    border-bottom: solid 8px transparent;
  }

  &::-webkit-scrollbar-track:vertical {
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
  }

  &::-webkit-scrollbar-corner { background: var(--page-background-light) }

}

.table-head__row {
  position: sticky;
  top: 0;
  background-color: var(--panel-background);
}

.table-head__cell {
  min-width: 180px;
  padding: 12px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--label-text);
}

.table-row__data {
  //padding: 8px 12px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--main-text);
  background-color: var(--input-background);
  cursor: pointer;
}

.table-head__cell-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.cell__sort-icons-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
}

.cell__sort-icn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  & path {
    fill: var(--label-text);
  }

  &.up-icn {
    transform: translateX(-50%) translateY(-50%) rotate(180deg);
  }

  &.selected path {
    fill: var(--main-text);
  }
}

.cell__title {
  text-align: left;
}

.cell__sort-icn-btn {
  position: relative;
  width: 8px;
  height: 6px;
}

.delete-cell-th {
  min-width: 44px;
}

.delete-cell-td {
  min-width: 44px;
  padding: 6px 12px;

  & .table-row__delete-btn {
    padding-top: 4px;
    width: 20px;
    height: 20px;
    border-radius: 4px;

    &:hover {
      background-color: var(--background-hover);
    }

    &:active {
      background-color: var(--background-active);
    }
  }
}
