.radio__input-row {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.radio__input {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  background-color: #292929;
  border: 1px solid var(--input-border);
  border-radius: 50%;
  -webkit-appearance: none;

  &:disabled {
    background-color: transparent;
  }
}

.radio__input:checked::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: var(--button-normal);
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  content: '';
}

.radio__label {
  max-width: 70%;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  color: var(--main-text);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

