.search-input__container {
  position: relative;
}

.search-input {
  width: 100%;
  height: 100%;
  padding: 0 34px 0 8px;
  color: var(--main-text);
  background: var(--input-background);
  border-radius: 3px;
  border: 1px solid var(--input-border);
  font-family: inherit;

  &::placeholder {
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--input-placeholder);
  }

  &:not(:focus,:disabled):hover {
    border: 1px solid var(--input-hover-border);
    background-color: var(--input-background-hover);
  }

  &:placeholder-shown {
    // text-indent: 32px;
  }

  &:not(:placeholder-shown) {
    & ~ .search-input__search-icon {
      // display: none;
    }

    &:focus {
      & ~ .search-input__clear-button {
        // display: block;
      }
    }
  }

  &:focus {
    text-indent: 0;
    outline: none;

    & ~ .search-input__search-icon {
     //  display: none;
    }
  }

  &:focus::placeholder {
    opacity: 0;
  }
}

.search-input__clear-button {
  position: absolute;
  top: 10px;
  right: 12px;
  display: none;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-input__search-icon {
  position: absolute;
  top: 50%;
  right: 8px;
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  transform: translateY(-50%);
}
