.popup-menu {
  position: absolute;
  padding: 8px 0;
  z-index: 100;
  box-shadow: 0 2px 8px #272727;
  border-radius: 4px;
  background-color: var(--popup-background);
}

.user-logout__popup-menu {
  min-width: 221px;
  right: -20px;
  bottom: -48px;
}

.popup-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--title-text);

  &:not(:disabled):hover {
    background-color: var(--popup-item-hover);
  }

  &:disabled {
    color: var(--label-text);
    cursor: default;
  }

  &:not(:disabled):active {
    background-color: #2A2A2C;
  }
}

.user-logout__popup-menu-triangle {
  position: absolute;
  right: 37px;
  top: -6px;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  background-color: var(--popup-background);
}

