.blocks__container {
  display: flex;
  min-height: 100vh;
  padding-top: 52px;

  &.separated-container:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 56.65%;
    transform: translateX(-56.65%);
    border-right: 1px solid var(--separator-light);
  }
}

.block-parameters-row {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.blocks__right-panel {
  flex: 1;
  background-color: var(--page-background-light);
}

.blocks__scrollable-panel {
  position: relative;
  display: flex;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 52px);
  background-color: var(--page-background-light);

  &::-webkit-scrollbar {
    position: relative;
    background: transparent;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px rgba(153, 153, 153, 0.24);
    -webkit-border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    border-top: solid 2px transparent;
    border-bottom: solid 2px transparent;
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
    min-height: 30px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px var(--input-background);
  }
}

