.error-page__content {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: var(--page-background);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.error-page__title {
  font-size: 32px;
  line-height: 40px;
  color: var(--main-text);
}

.error-page__codes {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.error-page__code {
  color: var(--main-text);
}

.error-page__info {
  margin-top: 24px;
  display: flex;
  align-items: center;
  max-width: 528px;
  justify-content: space-around;
  color: var(--main-text);
  text-align: center;
}

.error-page__link {
  margin-top: 24px;
  font-size: 20px;
  line-height: 26px;
  color: var(--main-text);
  text-decoration: underline;
  text-underline-offset: 3px;
}


