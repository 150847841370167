.connection-points__container {
  padding: 24px 20px;
  width: 45.53%;
  height: fit-content;
  background-color: var(--page-background-light);
  cursor: default;
}

.connection-points__title {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--main-text);
}

.connection-points__sub-title {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-text);
}

.connection-points__point {
  border-bottom: 1px solid var(--separator-light);
  margin-bottom: 16px;

  &.output:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.connection-points__empty-message {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--title-text);
}

.hints__container {
  margin-top: 2px;
  width: 100%;
}

.hint {
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
}
