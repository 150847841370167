.option__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 10px 14px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--popup-background);
  }

  &.version-option-container {
    padding: 12px 10px 12px 0;
    height: 56px;
  }
}

.option__option-details {
  display: flex;
  align-items: center;
}

.option__id-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 56px;
}

.option__id {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--title-text);
}

.option__id-btn {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &:hover {
    background-color: var(--background-light-hover);
  }

  &:active {
    background-color: var(--background-light-active);
  }
}

.option__add-btn {
  padding: 6px 0;
  margin-top: 16px;
}

.option__scrollable {
  max-height: 216px;
  min-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    position: relative;
    background: transparent;
    width: 26px;
    height: 26px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px rgba(153, 153, 153, 0.24);
    -webkit-border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    border-top: solid 9.5px transparent;
    border-bottom: solid 9.5px transparent;
    border-left: solid 9.5px transparent;
    border-right: solid 9.5px transparent;
    min-height: 30px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px var(--input-background);
  }

  &::-webkit-scrollbar-track:vertical {
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
  }
}

.option__block-image {
  display: flex;
  align-items: center;
  text-align: center;
  width: 60px;
  height: 60px;
  margin-right: 16px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--label-text);
  border: 1px solid #4A4A4A;
  border-radius: 4px;
  background-color: var(--input-background);

  &.empty {
    padding: 0 3px;
  }
}

.radio__input-row.disabled {

  & .option__block-image {
    background-color: transparent;
  }

  & .radio__label {
    color: var(--label-text);
  }
}

.option__menu-btn-wrapper {
  position: relative;
}

.option__popup-menu {
  width: 200px;
  left: unset;
  right: 0;
}

.option__radio-btn-wrapper {
  display: flex;
  align-items: center;
  width: 200px;
  //overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.option__copied-message {
  position: absolute;
  right: -20px;
  top: 30px;
  padding: 8px 10px;
  background-color: var(--popup-background);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--label-text);
  border-radius: 4px;
  z-index: 10;

  &.menu-message {
    right: -40px;
    top: 22px;
  }
}
