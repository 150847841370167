.textarea__container {
  width: 100%;
}

.textarea {
  width: 100%;
  height: 100px;
  resize: none;
  padding: 8px;
  background-color: var(--input-background);
  color: var(--main-text);
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid var(--input-border);
  overflow-y: overlay;
  cursor: auto;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: var(--input-background-hover);
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 14px;
    z-index: 10000;
    cursor: auto;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(62, 62, 62, 0.8);
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 14px;
    min-height: 30px;
    cursor: default !important;
  }

  &::-webkit-scrollbar-track-piece
  {
    background-color: transparent;
    -webkit-border-radius: 6px;
    cursor: default !important;
  }

  &:disabled {
    background-color: transparent;
    color: var(--label-text);
  }
}
