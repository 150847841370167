.facility-block-details__container {
  width: 20.886%;
  border-left: 1px solid var(--separator-dark);
  background-color: var(--panel-background);
}

.facility-block-details__title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 20px 16px 12px 16px;
  cursor: default;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.facility-block-details__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
}

.facility-block-details__title-btn {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  &:not(:disabled):hover {
    background-color: var(--background-light-hover);
  }

  &:not(:disabled):active {
    background-color: var(--background-light-active);
  }

  &:disabled {
    cursor: default;
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.facility-block-details__parameters-container {
  border-bottom: 1px solid var(--popup-background);
}

.facility-block-details__parameters-title-row {
  padding: 12px 16px;
  background-color: #343434;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-text);
  cursor: default;
}

.facility-block-details__scrollable-panel {
  overflow-y: auto;
  overflow-x: hidden;
  //max-height: calc(100vh - 52px - 52px);

  &::-webkit-scrollbar {
    position: relative;
    background: transparent;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px rgba(153, 153, 153, 0.24);
    -webkit-border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    border-top: solid 2px transparent;
    border-bottom: solid 2px transparent;
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
    min-height: 30px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px var(--input-background);
  }

  &::-webkit-scrollbar-track:vertical {
    //border-left: solid 3px transparent;
    //border-right: solid 3px transparent;
  }
}

.facility-block-details__parameters-panel {
  padding: 24px 16px;
  cursor: default;

  & .block-parameters-row {
    flex-direction: column;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.facility-block-details__connection-points-panel {
  padding: 12px 0 24px 0;
}

.facility-block-details__connection-point-container {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid var(--popup-background);

  & .block-parameters-row {
    flex-direction: column;
  }
}

.facility-block-details__connection-point-toggle-list {
  display: flex;
  justify-content: flex-start;
  gap: 4px;

  &.expanded svg {
    transform: rotate(90deg);
  }
}

.facility-block-details__connection-point-top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;

  &:hover {
    background-color: var(--selected-item);

    & .facility-block-details__connection-point-btn {
      background-color: transparent;

      &:hover {
        background-color: #4B4C4E;
      }
    }
  }

  &:active:not(:focus-within) {
    background-color: #2D2D2D;
  }
}

.facility-block-details__connection-point-btn {
  width: 33.72%;
  min-width: 100px;
  display: flex;
  justify-content: space-evenly;
  border-radius: 4px;
  background-color: #404143;
  padding: 8px 0;
  color: var(--main-text);

  & svg {
    transform: rotate(270deg);
    & path {
      fill: var(--main-text);
    }
  }

  &.selected {
    background-color: #5A5B5D;
  }

  &:hover {
    background-color: #4B4C4E;
  }
}

.facility-block-details__connection-point-details {
  padding: 12px 16px 0 16px;
}
