.blocks-tree__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20.886%;
  border-right: 1px solid var(--separator-dark);
  background-color: var(--panel-background);
}

.blocks-tree__search-wrapper {
  width: 100%;
  padding: 12px 16px;
}

.blocks-tree__search-input-container {
  height: 32px;
}

.blocks-tree__blocks {
  overflow-y: overlay;
  overflow-x: hidden;
  max-height: calc(100vh - 52px - 56px - 64px);

  &::-webkit-scrollbar {
    background: transparent;
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(22, 22, 22, 0.5);
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 14px;
    min-height: 30px;
  }

  &::-webkit-scrollbar-track-piece
  {
    background-color: transparent;
    -webkit-border-radius: 6px;
  }
}

.block__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: 40px;
  width: 100%;
  padding: 12px 16px;

  &:not(.selected):hover {
    background-color: var(--selected-item);
  }

  &.selected {
    background-color: var(--page-background-light);
    cursor: default;

    & .block__delete-btn {
      display: flex;
    }
  }

  &:not(:first-child) {
    border-top: 1px solid var(--popup-background);
  }

  &:last-child {
    border-bottom: 1px solid var(--popup-background);
  }
}

.block__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-text);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.block__delete-btn {
  display: none;
  align-items: center;
  justify-content: space-around;
  min-width: 20px;
  height: 20px;
  border-radius: 4px;

  &:hover {
    background-color: var(--background-light-hover);
  }

  &:active {
    background-color: var(--background-light-active);
  }
}

.blocks-tree__btn-wrapper {
  width: 100%;
  padding: 12px 16px;
  background: var(--input-background);
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
}

.blocks-tree__btn {
  width: 100%;
}

.blocks-tree__blocks-empty-message {
  padding: 12px 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--title-text);
}

.article__container {
  width: 50%;
  padding: 24px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--main-text);
}
