.home-page__container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: var(--page-background-light);
}

.home-page__content {
  width: 1024px;
  margin: 0 auto;
  padding-top: calc(104px + 58px);
  padding-bottom: 58px;
}

.home-page__title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: var(--main-text);
  cursor: default;
}

.home-page__controls-row {
  display: flex;
  margin: 26px 0 24px 0;
  align-items: center;
  justify-content: space-between;
}

.home-page__search-input-wrapper {
  width: 380px;
}

.home-page__search-input-container {
  height: 40px;
}

.home-page__empty-message {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--main-text);
}
