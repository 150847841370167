.parameters__container {
  padding: 24px 20px;
  width: 45.53%;
  height: fit-content;
  //border-right: 1px solid var(--separator-light);
  background-color: var(--page-background-light);
  cursor: default;
}

.parameters__title {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--main-text);
}

.parameters__image-row {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
}

.parameters__image {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 19.43vw;
  height: 19.43vw;
  background-color: var(--page-background);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--label-text);
}

.parameters__image-btn {
  height: 40px;
}

.block-parameters-element {
  flex: 1;
}

.parameters__image-input {
  display: none;
}
