.table-panel__container {
  padding: 20px;
  flex: 1;
  background-color: var(--page-background-light);
  cursor: default;
}

.toggle-list__container {
  border-bottom: 1px solid var(--popup-background);

  & .block__container:last-child {
    border-bottom: none;
  }
}

.block__container.toggle-list__category {
  border-top: none;
  border-bottom: none;
  justify-content: flex-start;
  gap: 4px;

  &.expanded svg {
    transform: rotate(90deg);
  }
}

.block__container.toggle-list__item {
  border-top: none;
  border-bottom: none;

  & .block__title {
    padding-left: 20px;
  }
}

.table-panel__add-row-button {
  margin-top: 16px;
  width: 200px;
}
