.connections-panel__container {
  width: 100%;
  background-color: var(--panel-background);
  border-radius: 4px;
  border: 1px solid var(--separator-dark);
}

.connections-panel__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: var(--input-background);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--main-text);
  border-radius: 4px 4px 0 0;

  &.expanded .errors-panel__icn-toggle {
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
  }
}

.connections-panel__cp-name {
  margin-right: 20px;
}

.connections-panel__close-btn {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 4px;

  &:hover {
    background-color: var(--background-hover);
  }

  &:active {
    background-color: var(--background-active);
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.connection-row__container {
  padding: 0 12px 0 0;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3A3A3A;

  &:nth-child(2n) {
    background-color: var(--main-text-dark);
  }

  &.input-row-container {
    height: 39px;
    padding-left: 12px;
  }

  &.errors-row__container {
    justify-content: flex-start;
    gap: 24px;
  }
}

.block__control-arrows.connection-row__arrows {
  display: block;

  &.invisible {
    opacity: 0;
  }

  &.labels-row__arrows {
    height: 33px;
  }
}

.connection-row__priority-order {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #B9BABA;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--panel-background);

  &.invisible {
    opacity: 0;
  }

  &.input-point-order {
    width: 12px;
    height: 12px;
  }
}

.connection-row__select-container {
  width: 27.68%;
}

.connections-panel__scrollable {
  max-height: calc(100vh - 582px);
  min-height: 140px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    position: relative;
    background: transparent;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px rgba(153, 153, 153, 0.24);
    -webkit-border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    border-top: solid 2px transparent;
    border-bottom: solid 2px transparent;
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
    min-height: 30px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px var(--input-background);
  }
}

.labels-row__container {
  display: flex;
  padding-right: 12px;
  align-items: center;
  justify-content: space-between;
  cursor: default;

  &.input-row-container {
    height: 32px;
    padding-left: 12px;
  }
}

.facility__delete-btn.labels-row__btn {
  &.invisible {
    opacity: 0;
  }
}

.labels-row__label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--label-text);
}

.control-row__add-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--label-text);

  &:not(:disabled):hover {
    color: var(--main-text);

    & svg path {
      stroke: var(--main-text);
    }
  }

  &:not(:disabled):active {
    color: var(--title-text);

    & svg path {
      stroke: var(--title-text);
    }
  }

  &:disabled {
    color: #535353;
    cursor: default;

    & svg path {
      stroke: #535353;
    }
  }
}

.connections-panel__empty-message {
  background-color: #3A3A3A;
  padding: 36px 76px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--label-text);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &.input-empty-message {
    padding: 36px 48px;
  }

  &.errors-panel__empty-message {
    padding: 36px 16px;
  }
}

.errors-panel__title {
  position: relative;
  width: 168px;
  display: flex;
  align-items: center;
  gap: 12px;

  & .errors-panel__errors-count-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    & svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.errors-row {
  padding: 12px 0 12px 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-text);

  &.errors-row__element {
    width: 40%;
  }
}

.errors-panel__errors-count {
  margin-left: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--error);
}
